import { GLOBAL, addLoadEventHandler } from 'saddlebag-browser';
import logger from 'saddlebag-logger';
import observer from 'saddlebag-observer';

import { name as componentName, version as componentVersion } from '../package.json';

import { logWarning } from './utils/logger';
import { loadTags } from './utils/loadTags';

const WARNING_TIMEOUT = 5000;

const App = props => {
  const tagManagerProps = { ...props };
  const $window = GLOBAL.getWindow();
  // eslint-disable-next-line no-underscore-dangle
  $window.__tagmanagerProps = tagManagerProps;

  // Experiment
  // Differentiate skippy and dbook-skippy
  if (tagManagerProps.pagetype === 'skippy') {
    if (
      tagManagerProps.skippyUrl.indexOf('&facilitated=true') &&
      $window.context.redirect_delay === 500
    ) {
      tagManagerProps.pagetype = 'dbook-skippy';
    }
  }

  // Count a Successful Launch of the Tag Manager
  logger.logOperationalEvent({
    eventName: 'launches',
    component: componentName,
    version: componentVersion,
    page: tagManagerProps.pagetype,
  });

  const loadTagsNotCalledTimeout = setTimeout(() => {
    logWarning(`loadTags was not called within ${WARNING_TIMEOUT}ms of launching tag manager`);
  }, WARNING_TIMEOUT);

  let pageLoaded = false;

  observer.subscribe('tracking-preferences-changed', async () => {
    await loadTags(pageLoaded, tagManagerProps, loadTagsNotCalledTimeout);
  });

  addLoadEventHandler(async () => {
    pageLoaded = true;
    await loadTags(pageLoaded, tagManagerProps, loadTagsNotCalledTimeout);
  });

  return null;
};

export default App;
